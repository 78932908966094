
body{
  background-color: var(--bs-body-bg);
}



.Navlogo {
  src: url('/src/assets/Planet.png');
}

@media all and (max-width: 320px) {

  .image-home{
    background-image: url('/src/assets/SavePaper.jpg');
    height: 200px;
    width: 100%;
    background-size: cover;
  }
  
  .container{
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    min-height: 100%;
  }
  
  
  .card{
    width:17.7rem;
    height:auto;
  }
  p {
    text-indent: 0ch;
  }
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    display: inline-block;
    height:auto;
    size: sm;
  }
  .form-control{
    max-width: 20rem;
    width:auto;
  }
  form {
    width:auto;
  }
  
}

@media all and (min-width:321px) and (max-width: 375px) {
  .container{
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    min-height: 100%;
  }
  
  .image-home{
    background-image: url('/src/assets/SavePaper.jpg');
    height: 300px;
    width: 100%;
    background-size: cover;
  }
  .card{
    width:17.7rem;
    height:auto;
  }
  p {
    text-indent: 0ch;
  }
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    display: inline-block;
    height:auto;
    size: sm;
  }
  .form-control{
    max-width: 20rem;
    width:auto;
  }
  form {
    width:auto;
  }
}
/* 425px */
@media all and (min-width:376px) and (max-width: 425px) {
  .container{
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    min-height: 100%;
  }
  
  .image-home{
    background-image: url('/src/assets/SavePaper.jpg');
    height: 400px;
    width: 100%;
    background-size: cover;
  }
  
  .card{
    width:20rem;
    height:auto;
  }
  p {
    text-indent: 0ch;
  }
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    display: inline-block;
    height:auto;
    size: sm;
  }
  .form-control{
    max-width: 20rem;
    width:auto;
  }
  form {
    width:auto;
  }
}

@media all and (min-width:426px) and (max-width: 768px) {
  .container{
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    min-height: 100%;
  }
  
  .image-home{
    background-image: url('/src/assets/SavePaper.jpg');
    height: 400px;
    width: 100%;
    background-size: cover;
  }
  .card{
    width:23rem;
    height:auto;
  }
  p {
    text-indent: 0ch;
  }
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    display: inline-block;
    height:auto;
    size: sm;
  }
  .form-control{
    max-width: 20rem;
    width:auto;
  }
  form {
    width:auto;
  }
}
@media all and (min-width:769px) and (max-width: 1024px) {
  .container{
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    min-height: 100%;
  }
  
  .image-home{
    background-image: url('/src/assets/SavePaper.jpg');
    height: 600px;
    width: 100%;
    background-size: cover;
  }
  .card{
    width:23rem;
    height:auto;
  }
  p {
    text-indent: 0ch;
  }
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    display: inline-block;
    height:auto;
    size: sm;
  }
  .form-control{
    max-width: 20rem;
    width:auto;
  }
  form {
    width:auto;
  }}
/** general Settings **/
.App {
  text-align: center;
}

@media all and (min-width:1025px) {
  .container{
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    min-height: 100%;
  }
  
  .image-home{
    background-image: url('/src/assets/SavePaper.jpg');
    height: 600px;
    width: 100%;
    background-size: cover;
  }
  
  .card{
    width:25rem;
    height:auto;
  }
p {
  text-indent: 0ch;
}
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  display: inline-block;
  height:auto;
  size: sm;
}
.form-control{
  max-width: 20rem;
  width:auto;
}
form {
  width:auto;
}
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.color-nav {
  background-color: #0e67b4;
}
.btn-options {
  background-color: #0e67b4;
  color: #271801;
}
.navbarbrand-class {
  color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}



button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

